<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('table.head.memRegDt') }}</span></div>
          <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
        </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('table.head.memType') }}</span></div>
          <select class="reqMember" v-model="reqData.userType">
            <option value="">{{ $t('common.entire') }}</option>
            <option value="N">{{ $t('searchArea.user') }}</option>
            <option value="P">{{ $t('searchArea.partner') }}</option>
          </select>
        </div>
        <div class="box-ui-check searchbar-content">
          <div class="toggle-container">
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('user.login') }}</span>
              <select class="w80" v-model="reqData.loginYn">
                <option value="">{{ $t('common.all') }}</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('user.bet') }}</span>
              <select class="w80" v-model="reqData.betYn">
                <option value="">{{ $t('common.all') }}</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item">
              <span class="toggle-title">{{ $t('user.deleted') }}</span>
              <select class="w80" v-model="reqData.memDelYn">
                <option value="">{{ $t('common.all') }}</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item" v-if="selectOption !== 'blackYn'">
              <span class="toggle-title">{{ $t('user.blacked') }}</span>
              <select class="w80" v-model="reqData.memBlackYn">
                <option value="">{{ $t('common.all') }}</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
            <div class="toggle-item" v-if="selectOption !== 'lockYn'">
              <span class="toggle-title">{{ $t('user.blocked') }}</span>
              <select class="w80" v-model="reqData.memLockYn">
                <option value="">{{ $t('common.all') }}</option>
                <option value="Y">ON</option>
                <option value="N">OFF</option>
              </select>
            </div>
          </div>
        </div>
        <div class="searchbar-content">
          <div class="title"><span>{{ $t('table.head.category') }}</span></div>
          <div>
            <select class="mr-5" v-model="searchOptionText.option">
              <option value="memId">{{ $t('searchArea.id') }}</option>
              <option value="memNick">{{ $t('searchArea.nick') }}</option>
              <option value="memName">{{ $t('searchArea.account') }}</option>
              <option value="memPhone">{{ $t('searchArea.tel') }}</option>
              <option value="recommenderId">{{ $t('searchArea.recommenderId') }}</option>
            </select>
            <input type="text" class="mr-5" v-model="searchOptionText.text"/>
            <button class="btn-search mr-5" type="button" @click="pageSeach(1)">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div class="searchbar-content">
          <div class="title"><span>{{ $t('common.sOptiont') }}</span></div>
          <select v-model="reqData.orderStr">
            <option value="">{{ $t('common.ojoin') }}</option>
            <!-- <option>아이디 오름차순</option>
            <option>아이디 내림차순</option> -->
            <option value="CASH_DESC">{{ $t('common.olm') }}</option>
            <option value="CASH_ASC">{{ $t('common.osm') }}</option>
            <option value="POINT_DESC">{{ $t('common.olp') }}</option>
            <option value="POINT_ASC">{{ $t('common.osp') }}</option>
            <option value="CASH_IN_DESC">{{ $t('common.old') }}</option>
            <option value="CASH_IN_ASC">{{ $t('common.osd') }}</option>
            <option value="CASH_OUT_DESC">{{ $t('common.olw') }}</option>
            <option value="CASH_OUT_ASC">{{ $t('common.osw') }}</option>
            <option value="WIN_LOSE_DESC">{{ $t('common.olwl') }}</option>
            <option value="WIN_LOSE_ASC">{{ $t('common.oswl') }}</option>
            <!-- <option value="">{{ $t('common.olr') }}</option>
            <option value="">{{ $t('common.oll') }}</option> -->
          </select>
        </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ totalInfo.totalCnt }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.normalCnt`) }}</span> : <span class="value">{{ totalInfo.normalCnt }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.partnerCnt`) }}</span> : <span class="value">{{ totalInfo.partnerCnt }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.yBetCnt`) }}</span> : <span class="value">{{ totalInfo.yBetCnt }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.yRecommendCnt`) }}</span> : <span class="value">{{ totalInfo.yRecommendCnt }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.yLoginCnt`) }}</span> : <span class="value">{{ totalInfo.yLoginCnt }}</span>
          </div>
        </div>
        <!-- <button type="button" class="btn-layout" :class="wlContentsActive ? 'btn-red' : 'btn-blue'" @click="wlContentsToggle">{{ $t('table.head.wl') }} {{ wlContentsActive ? "닫기" : "열기" }}</button> -->
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col style="width:4%">
            <col style="width:7%">
            <col style="width:8%">
            <col style="width:7%">
            <col style="width:5%">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
          </colgroup>
          <table-head-sorting :headInfo="headInfo" @sort="onSort" :orderColumn="reqData.orderColumn" :orderType="reqData.orderType" /><!-- :class="{ 'sticky': isSticky }" :style="{ width: headerWidth + 'px' }" -->
          <tbody ref="tableContainer">
          <tr v-for="(item, idx) in memList" :key="idx">
            <td>
              <span class="roboto">{{ Number(this.pageInfo.tatal_list_count) - ((this.pageInfo.page - 1) * 30 + idx) }}</span>
            </td>
            <td style="min-width:100px;">
              <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">
                <template v-if="item.memDelYn == 'Y'">
                  <del>{{ item.memId }}({{ item.memLevel }})</del>
                </template>
                <template v-else>
                  {{ item.memId }}({{ item.memLevel }})
                </template>
              </button>
              <span class="nameId">
                  <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">
                    <template v-if="item.memDelYn == 'Y'">
                      <del>{{ item.memNick }}</del>
                    </template>
                    <template v-else>
                      {{ item.memNick }}
                    </template>
                  </button>
                </span>
              <!-- <span class="names">
                <template v-if="item.isTopUserList">
                  <template v-for="(user, index) in item.topUserListCalculate" :key="user.recommenderId">
                    <a @click="detailOpen('partner', user.recommenderId)">
                      <span class="arrow">>></span>
                      <span :class="user.partnerLevel">{{user.recommenderId}}</span>
                    </a>
                  </template>
                </template>
              </span> -->
            </td>
            <!--td>
              <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">
                <template v-if="item.memDelYn == 'Y'">
                  <del>{{ item.memNick }}</del>
                </template>
                <template v-else>
                  {{ item.memNick }}
                </template>
              </button>
            </td-->
            <!--td>{{ item.memLevel }}</td-->
            <td>
              <div class="memo-btn">
                <a @click="item.isOpenMemo = !item.isOpenMemo">{{ item.memName }}</a>
                <div class="memo-box-wrap" v-if="item.isOpenMemo">
                  <div class="memo-box-in">
                    <div class="memo-box-title">Memo</div>
                    <div class="memo-box-content">
                      <textarea :placeholder="$t('txt.enterNote')" v-model="item.memo"></textarea>
                    </div>
                  </div>
                  <div class="memo-box-btn">
                    <a @click="setMemo(item)">{{ $t('searchArea.modify') }}</a>
                    <a @click="item.isOpenMemo = false;">{{ $t('searchArea.cancel') }}</a>
                  </div>
                </div>
              </div>
            </td>
            <td :class="item.partnerLevel">
              <template v-if="item.partnerLevelName === '일반회원'">
                <span class="box">{{ $t('common.member') }}</span>
              </template>
              <template v-else-if="item.partnerLevelName === '총본사'">
                <span class="box">{{ $t('common.seniorA') }}</span>
              </template>
              <template v-else-if="item.partnerLevelName === '대본사'">
                <span class="box">{{ $t('common.masterA') }}</span>
              </template>
              <template v-else-if="item.partnerLevelName === '부본사'">
                <span class="box">{{ $t('common.juniorA') }}</span>
              </template>
              <template v-else>
                <span class="box">{{item.partnerLevelName}}</span>
              </template>
            </td>
            <td>
              <div class="topwrap" v-if="item.isTopUserList">
                <select @change="onDetailOpen" v-if="item.isTopUserList" :class="item.partnerLevel" v-model="item.topUserListCalculate.slice().reverse()[0].recommenderId" class="select">
                  <template v-if="item.isTopUserList">
                    <template v-for="(user, index) in item.topUserListCalculate" :key="user.recommenderId">
                      <option :value="user.recommenderId" class="option" :class="user.partnerLevel">
                        <span class="topbox">[ {{computedPartnerLevel(user)}} ] </span>
                        <span :class="user.partnerLevel">{{user.recommenderId}}</span>
                      </option>
                    </template>
                  </template>
                </select>
                <i class="icon">+</i>

                <!-- <button @click="detailOpen('partner', item.selectedUserId)">이동</button> -->
              </div>
              <!-- <div v-if="item.isTopUserList">
              </div>
              <ul v-if="item.isTopUserList">
                <template v-if="item.isTopUserList">
                  <template v-for="(user, index) in item.topUserListCalculate" :key="user.recommenderId">
                    <li class="topuser">
                      <span class="topbox" :class="user.partnerLevel">{{computedPartnerLevel(user)}}</span>
                      <span :class="user.partnerLevel">{{user.recommenderId}}</span>
                    </li>
                  </template>
                </template>
              </ul> -->
            </td>
            <!--td>{{ item.recommenderId }}</td>
            <td>{{ item.recommenderNick }}</td>
            <td>{{ numberWithCommas(item.totalLoginCount) }}</td>
            <td>{{ numberWithCommas(item.totalBonusAmt) }}</td-->
            <!-- <td>
              <ul class="money">
                <li><em>{{ $t('table.body.cash') }}</em><span>{{ numberWithCommas(item.cashAmt) }}</span></li>
                <li><em>{{ $t('table.body.point') }}</em><span>{{ numberWithCommas(item.pointAmt) }}</span></li>
                <li><em>{{ $t('table.body.slotMoney') }}</em><span>{{ numberWithCommas(item.gameMoney) }}</span></li>
              </ul>
            </td> -->
            <td class="actionbtn">
              <div class="action">
                <a class="phone">
                  <img src="@/assets/img/icon_phone.svg" style="height:20px" @click="item.isPhone = !item.isPhone"/>
                  <span class="popup" v-if="item.isPhone">{{ item.memPhone }}</span>
                </a>
                <a class="acc">
                  <img src="@/assets/img/icon_account.svg" style="height:24px" @click="item.isbank = !item.isbank"/>
                  <span class="popup" v-if="item.isbank">{{ item.bank }}<br>{{ item.bankacc }}</span>
                </a>
              </div>
            </td>
            <td class="moneywl">
              <div class="btn-inout-wrap">
                <span>{{ numberWithCommas(item.cashAmt) }}</span>
                <a @click="openMemberPopup(item, 'isOpenCash')" class="btn-inout">{{ $t('searchArea.listTypeB') }}</a>
              </div>
            </td>
            <td class="moneywl">
              <div class="btn-inout-wrap">
                <span>{{ numberWithCommas(item.pointAmt) }}</span>
                <a @click="openMemberPopup(item, 'isOpenPoint')" class="btn-inout">{{ $t('searchArea.listTypeB') }}</a>
              </div>
            </td>
            <td>
              <ul class="money">
                <li><em>{{ $t('table.body.totalCashInAmt') }}:</em><span>{{ numberWithCommas(item.totalCashInAmt) }}</span></li>
                <li><em>{{ $t('table.body.totalCashOutAmt') }}:</em><span>{{ numberWithCommas(item.totalCashOutAmt) }}</span></li>
              </ul>
            </td>
            <td class="rdc moneywl" v-if="item.totalCashDiff > 0">
              <span>{{ numberWithCommas(item.totalCashDiff) }}</span>
            </td>
            <td class="moneywl" v-if="item.totalCashDiff == 0">
              <span>{{ numberWithCommas(item.totalCashDiff) }}</span>
            </td>
            <td class="blc moneywl" v-if="item.totalCashDiff < 0">
              <span>{{ numberWithCommas(item.totalCashDiff) }}</span>
            </td>

            <td class="rdc moneywl" v-if="item.totalWlAmt > 0">
              <span>{{ numberWithCommas(item.totalWlAmt) }}</span>
            </td>
            <td class="moneywl" v-if="item.totalWlAmt == 0">
              <span>{{ numberWithCommas(item.totalWlAmt) }}</span>
            </td>
            <td class="blc moneywl" v-if="item.totalWlAmt < 0">
              <span>{{ numberWithCommas(item.totalWlAmt) }}</span>
            </td>
            <td class="actionbtn">
              <div class="action">
                <a class="btn-inout" @click="parentLinkMove('/bet/list/all', item.memId)">{{ $t('table.head.betList') }}</a>
                <template v-if="item.memColor === 'blue'">
                  <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-bl">{{ $t('table.head.userState') }}</a>
                </template>
                <template v-if="item.memColor === 'black'">
                  <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-bk">{{ $t('table.head.userState') }}</a>
                </template>
                <template v-if="item.memColor === 'red'">
                  <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-rd">{{ $t('table.head.userState') }}</a>
                </template>
                <!-- icon_lock.svg -->
                <!--a @click="openMemberPopup(item, 'isOpenCash')" class="moneyb"><img src="@/assets/img/icon_in.svg" /></a>
                <a @click="openMemberPopup(item, 'isOpenPoint')" class="pointb"><img src="@/assets/img/icon_point.svg" /></a>
                <a @click="openMemberPopup(item, 'isOpenMemo')" class="memob"><img src="@/assets/img/icon_memo.svg" /></a-->
                <a @click="openMemberPopup(item, 'isOpenPassword')"><img src="@/assets/img/icon_password.svg" /></a>
              </div>
            </td>
            <td v-if="wlContentsActive">{{ numberWithCommas(item.casinoWlAmt) }}</td>
            <td v-if="wlContentsActive">{{ numberWithCommas(item.eSportsWlAmt) }}</td>
            <td v-if="wlContentsActive">{{ numberWithCommas(item.mgWlAmt) }}</td>
            <td v-if="wlContentsActive">{{ numberWithCommas(item.slotWlAmt) }}</td>
            <td v-if="wlContentsActive">{{ numberWithCommas(item.sportsWlAmt) }}</td>
            <td v-if="wlContentsActive">{{ numberWithCommas(item.kgWlAmt) }}</td>
            <td>
              <div class="ipwrap">
                <span class="roboto">{{ item.lastLoginDt?item.lastLoginDt:'-' }}</span>
                <span class="ip rdc" @click="openMemberPopup(item, 'isOpenBLock')">{{ item.loginIp }}</span>
              </div>
            </td>
            <!--td>
              <div class="btn-wrap">
                <button type="button" class="btn-innerTable btn-status-change refuse btn-link" @click="deleteMember(item.memId)">{{ $t('button.del') }}</button>
              </div>
            </td-->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="memList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
    <div class="modal-wrapper" v-if="isOpenStatus || isOpenCash || isOpenPoint || isOpenPassword || isOpenBLock">
      <div class="modal-wrap2"  v-if="isOpenStatus">
        <div class="sub-title2">
          <h2>{{ selectMember.memName }} {{ $t('txt.statusNow') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenStatus = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li>
              <span>{{ $t('common.id') }} :</span>
              <span>{{ selectMember.memId }}</span>
            </li>
            <li>
              <span>{{ $t('common.nickName') }} :</span>
              <span class="fc-org">{{ selectMember.memNick }}</span>
            </li>
          </ul>
          <ul class="modal-set">
            <li>
              <em>{{ $t('common.setBlack') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isMemBlack"/>
            </li>
            <li>
              <em class="fc-rd">{{ $t('common.blockSet') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isMemLock"/>
            </li>
            <li>
              <em>{{ $t('user.isLogin') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isLogin"/>
            </li>
            <li>
              <em>{{ $t('table.head.betYn') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isBet"/>
            </li>
          </ul>
          <!--div class="content">
            <p>{{selectMember.memNick}} {{ $t('txt.statusNows') }}
             <template v-if="selectMember.memColor === 'blue'">
                <span class="normal">{{ $t('common.normal') }}</span>
             </template>
             <template v-if="selectMember.memColor === 'red'">
                <span class="stop">{{ $t('common.stop') }}</span>
             </template>
             <template v-if="selectMember.memColor === 'black'">
                <span class="black">{{ $t('common.block') }}</span>
             </template>
            </p>
          </div-->
          <p class="modal-txt">{{ $t('txt.changeStat') }}</p>
          <div class="modal-btns">
            <a @click="setStatus(selectMember)">{{ $t('common.save') }}</a>
          </div>
        </div>
      </div>
      <div class="modal-wrap2" v-if="isOpenCash">
        <div class="sub-title2">
          <h2>{{ $t('txt.memberManager') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenCash = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li>
              <span>{{ $t('common.id') }} :</span>
              <span>{{ selectMember.memId }}</span>
            </li>
            <li>
              <span>{{ $t('common.nickName') }} :</span>
              <span class="fc-org">{{ selectMember.memNick }}</span>
            </li>
            <li>
              <span>{{ $t('txt.currentCash') }} :</span>
              <span>{{ numberWithCommas(selectMember.cashAmt) }}</span>
            </li>
            <li>
              <span>{{ $t('table.head.aftM') }} :</span>
              <span class="onlyNum">
                    <input id="newCash" class="input" type="number" v-model="selectMember.newCash"/>
                    <label for="newCash">{{ numberWithCommas(selectMember.newCash || 0) }}</label>
                 </span>
            </li>
            <li>
              <textarea :placeholder="$t('txt.enterNote')" style="height:66px;" v-model="selectMember.memo"></textarea>
            </li>
          </ul>
          <div class="modal-btns">
            <a @click="setCash(selectMember, 'in')">{{ $t('searchArea.payment') }}</a>
            <a @click="setCash(selectMember, 'out')" class="btn">{{ $t('searchArea.retrieval') }}</a>
          </div>
        </div>
      </div>
      <div class="modal-wrap2" v-if="isOpenPoint">
        <div class="sub-title2">
          <h2>{{ $t('txt.memberManagerpoint') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenPoint = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li>
              <span>{{ $t('common.id') }} :</span>
              <span>{{ selectMember.memId }}</span>
            </li>
            <li>
              <span>{{ $t('common.nickName') }} :</span>
              <span class="fc-org">{{ selectMember.memNick }}</span>
            </li>
            <li>
              <span>{{ $t('txt.currentPoint') }} :</span>
              <span>{{ numberWithCommas(selectMember.pointAmt) }}</span>
            </li>
            <li>
              <span>{{ $t('table.head.aftPT') }} :</span>
              <span class="onlyNum">
                   <input id="newPoint" class="input" type="number" v-model="selectMember.newPoint"/>
                   <label for="newPoint">{{ numberWithCommas(selectMember.newPoint || 0) }}</label>
                </span>
            </li>
            <li>
              <textarea :placeholder="$t('txt.enterNote')" style="height:66px;" v-model="selectMember.memo"></textarea>
            </li>
          </ul>
          <div class="modal-btns">
            <a @click="setPoint(selectMember, 'in')">{{ $t('searchArea.payment') }}</a>
            <a @click="pointChange(selectMember)">{{ $t('searchArea.trans') }}</a>
            <a @click="setPoint(selectMember, 'out')" class="btn">{{ $t('searchArea.retrieval') }}</a>
          </div>
        </div>
      </div>
      <!--div class="modal-wrap memo" v-if="isOpenMemo">
        <article class="page-contents-wrap">
          <div class="sub-title">
            <h2>{{ $t('table.head.takeNote') }}</h2>
            <button class="btn" type="button" @click="isOpenMemo = false;selectMember = null"><i class="fa fa-times"></i></button>
          </div>
          <div class="page-content">
            <div class="content">
              <textarea :placeholder="$t('txt.enterNote')" v-model="selectMember.memo"></textarea>
              <div class="btnWrap">
                <a @click="setMemo(selectMember)">{{ $t('button.save') }}</a>
              </div>
            </div>
          </div>
        </article>
      </div-->
      <div class="modal-wrap2" v-if="isOpenPassword">
        <div class="sub-title2">
          <h2>{{ $t('txt.resetPWd') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenPassword = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li>
              <span>{{ $t('common.id') }} :</span>
              <span>{{ selectMember.memId }}</span>
            </li>
            <li>
              <span>{{ $t('common.nickName') }} :</span>
              <span class="fc-org">{{ selectMember.memNick }}</span>
            </li>
          </ul>
          <p class="modal-txt">{{ $t('txt.resetPWd3') }}</p>
          <div class="modal-btns">
            <a @click="setPassword(selectMember)">{{ $t('button.confirm') }}</a>
            <a class="btn" @click="isOpenPassword = false;selectMember = null">{{ $t('searchArea.cancel') }}</a>
          </div>
        </div>
      </div>
      <div class="modal-wrap2" v-if="isOpenBLock">
        <div class="sub-title2">
          <h2>{{ $t('common.ipBlack') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenBLock = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li class="ip-txt">
              <span>IP :</span>
              <span class="fc-org">{{ selectMember.loginIp }}</span>
            </li>
          </ul>
          <p class="modal-txt">{{ $t('txt.blockIP') }}</p>
          <div class="modal-btns">
            <a @click="setDenyIP(selectMember)">{{ $t('button.confirm') }}</a>
            <a class="btn" @click="isOpenBLock = false;selectMember = null">{{ $t('searchArea.cancel') }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--div class="loadmodal">
     <div class="cloading"></div>
  </div-->
</template>

<script>
// import SearchBar from '@/components/main/top/SearchBar.vue'
import lodash from 'lodash'
import TableHeadSorting from '@/components/main/table/HeadSorting.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import UiSelect from '@/components/ui/Select'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import {
  cashIn,
  cashOut,
  memberPointInOut,
  memberList,
  memberModify,
  setMemberMemo,
  setMemberResetPassword,
  memberToAdminPoint, setMemberDenyIP
} from '@/api/member.js'
import { getDateStr } from '@/libs/utils'
import { CMS_USER_LIST_SELECT_OPTION, TOP_PARTNER_LEVEL } from '@/libs/constants'
import { mapState } from 'vuex'
import Memo from '@/components/common/memo'
// import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
export default {
  name: 'UserList',
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHeadSorting,
    // BtnHideOpen,
    SearchFilter,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      isPhone: false,
      isbank: false,
      selectMember: null,
      isOpenStatus: false,
      isOpenCash: false,
      isOpenPoint: false,
      isOpenMemo: false,
      isOpenPassword: false,
      isOpenBLock: false,
      tableName: this.$route.name,
      isSticky: false,
      headInfo: {
        fstColumn: false,
        dataList: [
          { key: 'index' },
          { key: 'memIdNick' }, //, sort: true
          { key: 'bankname' },
          { key: 'level' },
          { key: 'upper' },
          { key: 'action2' },
          { key: 'cashAmt' },
          { key: 'pointAmt' },
          { key: 'allInOut' }, //, sort: true, sortKey: 'totalCashInAmt'
          { key: 'inoutAmt' },
          { key: 'betwlt' },
          { key: 'action' },
          { key: 'lastLoginIp' }
        ]
      },
      modalsActive: false,
      selectOption: window.localStorage.getItem(CMS_USER_LIST_SELECT_OPTION) || 'option',
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        siteId: '',
        recommenderId: '',
        memLevel: '',
        memId: '',
        startDate: '',
        endDate: '',
        memStatus: '1',
        loginYn: '',
        betYn: '',
        memDelYn: 'N',
        memBlackYn: 'N',
        memLockYn: 'N',
        orderColumn: 'redDt',
        orderType: 'DESC',
        orderStr: '',
        userType: ''
      },
      optionCheck: {
        loginYn: true,
        betYn: false,
        recommendYn: false,
        memDelYn: false
      },
      blackYn: false,
      lockYn: false,
      additionalHead: ['casino', 'eSports', 'game', 'slot', 'sports', 'kGame'],
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: false,
            opts: [
              {
                text: 'id',
                value: 'memId'
              },
              {
                text: 'recommenderId',
                value: 'recommenderId'
              }
            ]
          }
        ]
      },
      selectOptions: [
        {
          useAll: true,
          opts: [
            {
              title: 'subUser',
              orders: ['many', 'few']
            }
          ]
        }
      ],
      checkList: [
        {
          name: 'loginYn',
          checked: true,
          text: '로그인가능'
        },
        {
          name: 'betYn',
          checked: true,
          text: '베팅가능'
        },
        {
          name: 'recommenderYn',
          checked: true,
          text: '추천가능'
        },
        {
          name: 'recommenderYn',
          checked: true,
          text: '추천가능'
        }
      ],
      searchOptionText: {
        option: 'memId',
        text: ''
      },
      tableData: [],
      btnText: '보기',
      memList: [],
      pageInfo: {},
      totalInfo: {},
      wlContentsActive: false,
      startDefault: null,
      endDefault: null,
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      headerWidth: 0
    }
  },
  computed: {
    ...mapState([
      'siteOption'
    ])
  },
  watch: {
    selectOption: function (val) {
      this.setOption(val)
    },
    'reqData.orderStr' () {
      this.setTableData(1)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.setHeaderWidth()
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async setDenyIP (item) {
      const req = {
        memId: item.memId,
        ip: item.loginIp
      }
      const res = await setMemberDenyIP(req)
      if (res.resultCode === '0') {
        alert('로그인 IP 차단 완료')
      } else {
        alert('로그인 IP 차단, 다시 시도해주세요.')
      }
      this.isOpenBLock = false
    },
    getDomain () {
      return location.host
    },
    parentLinkMove (linkTarget, memId) {
      window.open(`${linkTarget}?id=${memId}`, '_blank')
    },
    handleScroll () {
      if (window.scrollY > 300) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
    handleResize () {
      this.setHeaderWidth()
    },
    setHeaderWidth () {
      const tableContainerWidth = this.$refs.tableContainer.clientWidth
      this.headerWidth = tableContainerWidth
    },
    block () {
      if (!confirm('해당 IP를 차단시겠습니까?')) {
        alert('취소를 누르셨습니다.')
      } else {
        alert('차단 완료')
      }
    },
    copy (cop) {
      navigator.clipboard.writeText(cop)
        .then(() => {
          alert('클립보드에 복사했습니다')
        })
    },
    pointChange (user) {
      const params = {
        siteId: this.siteOption.siteId,
        memId: user.memId,
        pointAmt: Number(this.selectMember.newPoint)
      }

      if (confirm('포인트 전환 하시겠습니까?')) {
        this.emitter.emit('Loading', true)
        memberToAdminPoint(params).then(res => {
          console.log(res)
          if (res.resultCode === '0') {
            this.selectMember.pointAmt = this.selectMember.pointAmt - Number(this.selectMember.newPoint)
            this.selectMember.newPoint = ''
            alert('포인트 전환 되었습니다.')
          } else {
            alert(res.resultMessage)
          }

          this.emitter.emit('Loading', false)
        })
        item.newPoint = ''
        this.isOpenPoint = false
      }
    },
    computedPartnerLevel (user) {
      return TOP_PARTNER_LEVEL[user.partnerLevel]
    },
    onDetailOpen (event) {
      const selectedRecommenderId = event.target.value
      this.detailOpen('partner', selectedRecommenderId)
    },
    async setOption (val) {
      console.log(val)
      window.localStorage.setItem(CMS_USER_LIST_SELECT_OPTION, val)
      switch (val) {
        case 'all':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = ''), (this.reqData.todayLoginYn = '')
          break
        case 'option':
          // this.optionCheck.betYn=true,
          // this.optionCheck.recommendYn=true,
          // this.optionCheck.todayLoginYn=true,
          // this.optionCheck.loginYn=true,
          // this.optionCheck.memDelYn=false,
          this.reqData.todayLoginYn = ''
          break
        case 'loginY':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = 'Y'), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
        case 'loginN':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
        case 'betY':
          (this.reqData.betYn = 'Y'), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
        case 'betN':
          (this.reqData.betYn = 'N'), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
        case 'recommendY':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = 'Y'), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
        case 'recommendN':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
        case 'delYn':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'Y'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = 'N'), (this.reqData.memLockYn = 'N')
          break
        case 'blackYn':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = 'Y'), (this.reqData.memLockYn = 'N')
          break
        case 'lockYn':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = ''), (this.reqData.memBlackYn = 'N'), (this.reqData.memLockYn = 'Y')
          break
        case 'todayLoginY':
          (this.reqData.betYn = ''), (this.reqData.recommendYn = ''), (this.reqData.todayLoginYn = ''), (this.reqData.loginYn = ''), (this.reqData.memDelYn = 'N'), (this.reqData.todayLoginYn = 'Y'), (this.reqData.memBlackYn = ''), (this.reqData.memLockYn = '')
          break
      }
    },
    async setStatus (item) {
      const req = {
        memId: item.memId,
        memBlackYn: item.isMemBlack === true ? 'Y' : 'N',
        memLockYn: item.isMemLock === true ? 'Y' : 'N',
        loginYn: item.isLogin === true ? 'Y' : 'N',
        betYn: item.isBet === true ? 'Y' : 'N'
      }
      const res = await setMemberMemo(req)
      if (res.resultCode === '0') {
        item.memBlackYn = req.memBlackYn
        item.memLockYn = req.memLockYn

        item.memColor = 'blue'
        if (item.isMemBlack === true) {
          item.memColor = 'black'
        }
        if (item.isMemLock === true) {
          item.memColor = 'red'
        }
        alert('상태 수정 완료')
      } else {
        alert('상태 수정  실패, 다시 시도해주세요.')
      }
      this.isOpenStatus = false
    },
    async setCash (item, type) {
      if (!item.newCash) {
        alert('금액을 입력해주세요.')
      } else {
        if (type === 'in') {
          const req = {
            memId: item.memId,
            inAmt: item.newCash,
            memo: item.memo || '',
            type: '',
            inBonusYn: 'N'
          }
          if (confirm('지급처리하시겠습니까?')) {
            const res = await cashIn(req)
            if (res.resultCode === '0') {
              alert('지급완료')
              item.cashAmt = item.cashAmt + item.newCash
              return
            } else {
              alert('지급실패, 다시 시도해주세요.')
            }
          }
        } else {
          const req = {
            memId: item.memId,
            outAmt: item.newCash,
            memo: item.memo || '',
            type: '',
            bonusRollingYn: 'N'
          }
          if (confirm('회수처리하시겠습니까?')) {
            const res = await cashOut(req)
            if (res.resultCode === '0') {
              alert('회수 완료')
              item.cashAmt = item.cashAmt - item.newCash
              return
            } else {
              if (res.resultCode === 'C702') {
                alert('회수할 금액이 유저 보유금보다 많을 수 없습니다.')
              } else if (res.resultCode === 'C099') {
                alert(res.resultMessage)
              } else {
                alert('회수실패, 다시 시도해주세요.')
              }
            }
          }
        }
        item.newCash = ''
        this.isOpenCash = false
      }
    },
    async setPoint (item, type) {
      let res = ''
      if (!item.newPoint) {
        alert('금액을 입력해주세요.')
      } else {
        if (type === 'in') {
          const req = {
            memId: item.memId,
            pointType: 2,
            pointDesc: '관리자 포인트 지급',
            pointAmt: item.newPoint,
            memo: item.memo || ''
          }
          if (confirm('지급처리 하시겠습니까?')) {
            res = await memberPointInOut(req)
            if (res.resultCode === '0') {
              alert('지급완료')
              item.pointAmt = item.pointAmt + item.newPoint
            } else if (res.resultCode === 'P006') {
              alert('포인트지급,회수 처리는 1분에 1건씩 처리됩니다. 잠시후 다시 시도해주세요')
            } else {
              alert('지급실패, 다시 시도해주세요.')
            }
          }
        } else {
          if (item.pointAmt < item.newPoint) {
            alert('회수포인트는 보유포인트보다 적어야 합니다.')
            return
          }
          const req = {
            memId: item.memId,
            pointType: -2,
            pointDesc: '관리자 포인트 회수',
            pointAmt: item.newPoint * -1,
            memo: item.memo || ''
          }
          if (confirm('회수처리 하시겠습니까?')) {
            res = await memberPointInOut(req)
            if (res.resultCode === '0') {
              alert('회수완료')
              item.pointAmt = item.pointAmt - item.newPoint
            } else {
              alert('회수실패, 다시 시도해주세요.')
            }
          }
        }
        item.newPoint = ''
        this.isOpenPoint = false
      }
    },
    async setMemo (item) {
      if (!item.memo) {
        alert('내용을 입력해주세요.')
      } else {
        const req = {
          memId: item.memId,
          memo: item.memo
        }
        const res = await setMemberMemo(req)
        if (res.resultCode === '0') {
          alert('메모 입력 완료')
        } else {
          alert('메모입력 실패, 다시 시도해주세요.')
        }
        item.isOpenMemo = false
      }
    },
    async setPassword (item) {
      const req = {
        memId: item.memId
      }
      const res = await setMemberResetPassword(req)
      if (res.resultCode === '0') {
        alert('비밀번호 초기화 완료')
      } else {
        alert('비밀번호 초기화 실패, 다시 시도해주세요.')
      }
      this.isOpenPassword = false
    },
    openMemberPopup (item, type) {
      this[type] = !this[type]
      this.selectMember = item
      this.selectMember.memo = ''
    },
    onSort (key, type) {
      this.reqData.orderType = type
      this.reqData.orderColumn = key
      this.setTableData()
    },
    setStartDate (date) {
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setWlStartDate (date) {
      this.reqData.wlStartDate = getDateStr(date[0], 'yyyy-MM-dd')
    },
    setWlEndDate (date) {
      this.reqData.wlEndDate = getDateStr(date[0], 'yyyy-MM-dd')
    },
    async deleteMember (memId) {
      const reqData = {
        memId,
        memDelYn: 'Y'
      }
      const res = await memberModify(reqData)
      if (res.resultCode === '0') {
        alert('회원삭제 완료')
      } else {
        alert('삭제 실패, 다시 시도해주세요.')
      }
      this.setTableData(1)
    },
    pageSeach (pageNum) {
      // if (this.selectOption === 'option') {
      //   console.log(this.optionCheck)
      //   for (const item in this.optionCheck) {
      //     if (this.optionCheck[item]) {
      //       this.reqData[item] = 'Y'
      //     } else {
      //       this.reqData[item] = ''
      //     }
      //   }
      // }
      // if (this.selectOption !== 'blackYn') {
      //   if (this.blackYn) {
      //     this.reqData.memBlackYn = ''
      //   } else {
      //     this.reqData.memBlackYn = 'N'
      //   }
      // }
      // if (this.selectOption !== 'lockYn') {
      //   if (this.lockYn) {
      //     this.reqData.memLockYn = ''
      //   } else {
      //     this.reqData.memLockYn = 'N'
      //   }
      // }
      if (!this.searchOptionText.text) {
        this.reqData.memId = ''
        this.reqData.recommenderId = ''
        this.reqData.memNick = ''
        this.reqData.memName = ''
      } else {
        if (this.searchOptionText.option === 'memId') {
          this.reqData.memId = this.searchOptionText.text
          this.reqData.recommenderId = ''
          this.reqData.memNick = ''
          this.reqData.memName = ''
        } else if (this.searchOptionText.option === 'recommenderId') {
          this.reqData.memId = ''
          this.reqData.recommenderId = this.searchOptionText.text
          this.reqData.memNick = ''
          this.reqData.memName = ''
        } else if (this.searchOptionText.option === 'memNick') {
          this.reqData.memId = ''
          this.reqData.recommenderId = ''
          this.reqData.memNick = this.searchOptionText.text
          this.reqData.memName = ''
        } else if (this.searchOptionText.option === 'memName') {
          this.reqData.memId = ''
          this.reqData.recommenderId = ''
          this.reqData.memNick = ''
          this.reqData.memName = this.searchOptionText.text
        } else if (this.searchOptionText.option === 'memPhone') {
          this.reqData.memId = ''
          this.reqData.recommenderId = ''
          this.reqData.memNick = ''
          this.reqData.memName = ''
          this.reqData.memPhone = this.searchOptionText.text
        }
      }

      this.setTableData(pageNum)
    },
    wlContentsToggle () {
      const reverseArr = this.additionalHead.reverse()
      const addPosition = this.headInfo.dataList.indexOf('wl') + 1
      this.wlContentsActive = !this.wlContentsActive
      for (const item of reverseArr) {
        if (this.wlContentsActive) {
          this.headInfo.dataList.splice(addPosition, 0, item)
        } else {
          this.headInfo.dataList.splice(addPosition, 1)
        }
      }
    },
    openDetail_company () {
      // console.log(id)
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }
      if (this.reqData.orderStr) {
        this.reqData.orderColumn = ''
        this.reqData.orderType = ''
      } else {
        this.reqData.orderColumn = 'redDt'
        this.reqData.orderType = 'DESC'
      }

      const data = { ...this.reqData }
      console.log(data)
      const listRes = await memberList(data)
      console.log(listRes)
      const pageInfo = listRes.data.pageInfo
      this.pageInfo = pageInfo
      const totalInfo = listRes.data.totalInfo
      this.totalInfo = totalInfo
      const memList = listRes.data.list
      memList.forEach(item => {
        if (item.lastLoginDt) {
          const _date = new Date(item.lastLoginDt)
          item.lastLoginDt = this.getDateStr(_date, 'yy-MM-dd HH:mm:ss')
        }

        item.isMemLock = item.memLockYn === 'Y'
        item.isMemBlack = item.memBlackYn === 'Y'
        item.isLogin = item.loginYn === 'Y'
        item.isBet = item.betYn === 'Y'

        item.memColor = 'blue'

        if (item.memLockYn === 'Y') {
          item.memColor = 'red'
        } else {
          if (item.memBlackYn === 'Y') {
            item.memColor = 'black'
          }
        }
        for (const key in item) {
          if (item[key] !== 0 && !item[key]) {
            item[key] = '-'
          }
        }
        const topUserList = item.topUserList
        console.log(item.memName, item.topUserList)
        item.isTopUserList = topUserList.length > 0
        item.isTopUserListOver = topUserList.length > 0
        // topUserList.shift()
        item.topUserListCalculate = topUserList.reverse()
        item.isOpenMemo = false
      })

      this.memList = memList
      this.emitter.emit('Loading', false)
    }
  },
  payoutSave () {
    this.modalClose()
  },
  async created () {
    // this.setStartDate();
    // this.setEndDate();
    await this.setOption(this.selectOption)
    await this.pageSeach(1)
  }
}
</script>

<style scoped>
.w80 {
  width: 80px;
}
.fc-id, .fc-nick {
  font-size: 13px;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
}
.searchBar {margin: 0;background: none;}
.actionbtn {overflow: inherit;}
.action a {position: relative;}
/*.action a:hover:before {content: "";position: absolute;top: 25px;left: 50%;width: 0;height: 0;margin-left: -6px;border-right: 6px solid transparent;border-left: 6px solid transparent;border-bottom: 8px solid #353535;}
.action a:hover:after {display: block;}
.action a:after {display: none;width: 90px;height: 30px;line-height: 30px;position: absolute;top: 33px;left: 50%;margin-left: -45px;color: #fff;border-radius: 5px;font-size: 12px;text-align: center;background: #353535;z-index: 1;}
.memberb:after {content: "회원상태변경";}
.moneyb:after {content: "캐시 지급/회수";}
.pointb:after {content: "포인트 지급/회수";}
.memob:after {content: "메모";}
.pwb:after {content: "비밀번호 변경";}*/
.phone, .acc {
  position: relative;
}
.phone .popup::after, .acc .popup::after {
  border-color: #f5f5f5 transparent;
  border-style: solid;
  border-width: 8px 6px 0px 6.5px;
  content: '';
  display: block;
  position: absolute;
  bottom: -7px;
  width: 0;
  z-index: 1;
}

.phone .popup::before, .acc .popup::before {
  border-color: #bcc2c7 transparent;
  border-style: solid;
  border-width: 8px 6px 0 6.5px;
  content: '';
  display: block;
  position: absolute;
  bottom: -9px;
  width: 0;
  z-index: 0;
}
.phone .popup::after,
.phone .popup::before {right: 5px;}

.acc .popup::after,
.acc .popup::before {left: 5px;}
.popup {
  /*display: none;*/
  position: absolute;
  border-radius: 7px;
  border: solid 1px #bcc2c7;
  background-color: #f5f5f5;
  color: #585963;
  font-size: 11px;
  padding: 4px 9px;
  z-index: 1;
  bottom: 30px;
  /*left: 50%;
  transform: translateX(-50%);*/
}
.phone .popup {right: 0;}
.acc .popup {left: 0;}
.phone:hover .popup,
.acc:hover .popup {
  display: block;
}
/* .phone:after {content: "";}
a.acc:after {content: "우리은행 1234-54-4557788";width:170px;} */
.onlyNum {position: relative;}
.page-content .content .input#newCash {margin-top: 0;}
#newCash {
  width: 110px;
  height: 20px;
  color: transparent;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
#newCash::selection{
  background:none;
}
#newCash+label {
  font-size: 12px;
  background: #fff;
  color: #000;
  height: 20px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 110px;
  position: absolute;
  left:0;
  top:0;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
.page-content .content .input#newPoint {margin-top: 0;}
#newPoint {
  width: 110px;
  height: 20px;
  color: transparent;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
#newPoint::selection{
  background:none;
}
#newPoint+label {
  font-size: 12px;
  background: #fff;
  color: #000;
  height: 20px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 110px;
  position: absolute;
  left:0;
  top:0;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
.active .btn-t-inner {
  display: none;
}
.fstColumn.userList .levelColor {
  display: block;
}

.box-hideOpen .levelColor {
  background: rgb(255, 218, 38);
  width: 8px;
}
.mainTable >>> th:first-child {
  width:55px;
}
/*.mainTable >>> th:nth-child(6),
.mainTable >>> th:nth-child(7){
  width:285px;
}*/
.mainTable >>> th:nth-child(3) {
  /*width: 130px;*/
  background: url(~@/assets/img/icon_memos.svg) 72% center no-repeat;
}
/*.mainTable >>> th:nth-child(3) .title {position: relative;}
.mainTable >>> th:nth-child(3) .title::before {content: '(';}
.mainTable >>> th:nth-child(3) .title::after {content: ')';}*/
/*.mainTable tr:nth-child(even) td {background: #f7f7f7;}*/
/* .mainTable tr > td:nth-child(2) { width: 5%;}
.mainTable tr > td:nth-child(4) { width: 5%;} */
.nameId {display: block;margin: 12px 0 0;}
.names {display: flex;gap: 5px;justify-content: center;color: #0f84ee;min-width: 170px;width: 270px;max-width: 270px;flex-wrap: wrap;}
.names a {color: #0f84ee; font-size: 12px;}
.money li:first-child {margin-top: 0;}
.money li {display: flex;justify-content: space-between;align-items: center;margin-top: 7px;}
.money em {width: 60px;text-align: left;}
.money span {width: 102px;text-align: right;display: inline-block;font-family: 'Roboto';font-size: 14px;}
.moneywl span {font-family: 'Roboto';font-size: 14px;}
.action {display: flex;justify-content: center;align-items: center;gap: 10px;}
.ipwrap{display: flex;flex-direction: column;align-items: center;}
.ip {width: 130px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;word-break: break-all;font-family: 'Roboto';font-size: 14px;}
.rdc {color: #ff7e00;}
.blc {color: #0f84ee;}
.em {font-style: italic;}
.memo-btn {position: relative;background: transparent;}
.memo-btn > a {color: #898989;justify-content: center;}

/* modal */
.modal-wrapper {display: flex;}
.modal-wrap {padding: 0;width: 550px;min-width: 550px;margin-top: 350px;}
.page-contents-wrap {padding: 0;background: #353535;}
.sub-title {color: #fff;font-size: 14px;height: 42px;display: flex;align-items: center;justify-content: center;position: relative;border-bottom: 2px solid transparent;}
.sub-title .btn {position: absolute;right: 15px;background: no-repeat;font-size: 22px;width: auto;height: auto;cursor: pointer;}
.page-content {padding: 27px 0 25px;color: #fff;}
.page-content .content {text-align: center;}
.page-content .content p {font-size: 14px;text-align: center;}
.page-content .content p span {margin-left: 20px;display: inline-block;border-radius: 18px;padding: 1px 12px;}

.page-content .content p span.normal {background: #0f84ee;}
.page-content .content p span.black {background: #808080;}
.page-content .content p span.stop {background: #e1534e;}

.page-content .content .setting {display: flex;justify-content: center;gap: 50px;margin-top: 20px;text-align: center;}
.page-content .content .setting em {font-size: 16px;display: block;margin-bottom: 10px;}
.page-content .content .btnWrap {display: flex;align-items: center;justify-content: center;gap: 20px;margin-top: 20px;}
.page-content .content .btnWrap a {width: 168px;height: 35px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: #fff;cursor: pointer;}
.page-content .content .btnWrap a.btn {border: 1px solid #a4a4a4;box-sizing: border-box;background: #545454;}
.page-content .content .input {border: 1px solid #353535;font-size: 14px;width: 356px;height: 30px;margin-top: 20px;}
.page-content .content .input::placeholder, .page-content .content textarea::placeholder {color: #a4a4a4;}
.page-content .content textarea {resize: none;width: 480px;height: 112px;padding: 10px 12px;font-size: 14px;}

.modal-wrap.name .sub-title {border-color: #e1534e;}
.modal-wrap.name .sub-title .btn {color: #e1534e;}
.modal-wrap.name .btnWrap a {background: #e1534e;}

.modal-wrap.cash .sub-title {border-color: #369e17;}
.modal-wrap.cash .sub-title .btn {color: #369e17;}
.modal-wrap.cash .btnWrap a {background: #369e17;}

.modal-wrap.point .sub-title {border-color: #d322fd;}
.modal-wrap.point .sub-title .btn {color: #d322fd;}
.modal-wrap.point .btnWrap a {background: #d322fd;}

.modal-wrap.memo .sub-title {border-color: #0f84ee;}
.modal-wrap.memo .sub-title .btn {color: #0f84ee;}
.modal-wrap.memo .btnWrap a {background: #0f84ee;}

.modal-wrap.password .sub-title {border-color: #f6ac00;}
.modal-wrap.password .sub-title .btn {color: #f6ac00;}
.modal-wrap.password .btnWrap a {background: #f6ac00;}
.ft16 {font-size: 16px !important;}

/* .NORMAL {color: #6f7172;}
.PTN_1 {color: #eb7a3f;}
.PTN_2 {color: #82a6a4;}
.PTN_3 {color: #86822f;}
.PTN_4 {color: #806fad;}
.PTN_5 {color: #f6ac01;}
.NORMAL .box {background: #df663a;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_1 .box {background: #d98f00;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_2 .box {background: #af2a2e;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_3 .box {background: #1d505c;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_4 .box {background: #363b72;color: #fff;padding: 2px 4px;border-radius: 3px;}
.PTN_5 .box {background: #2b476e;color: #fff;padding: 2px 4px;border-radius: 3px;} */
.topuser .NORMAL {color: #b53f3f;}
.topuser .PTN_1 {color: #b51d44;}
.topuser .PTN_2 {color: #a300ab;}
.topuser .PTN_3 {color: #5b39bc;}
.topuser .PTN_4 {color: #754b23;}
.topuser .PTN_5 {color: #047972;}

.NORMAL {color: #b53f3f;}
.PTN_1 {color: #b51d44;}
.PTN_2 {color: #a300ab;}
.PTN_3 {color: #5b39bc;}
.PTN_4 {color: #754b23;}
.PTN_5 {color: #047972;}

.NORMAL:checked {color: #b53f3f;}
.PTN_1:checked {color: #b51d44;}
.PTN_2:checked {color: #a300ab;}
.PTN_3:checked {color: #5b39bc;}
.PTN_4:checked {color: #754b23;}
.PTN_5:checked {color: #047972;}

.box {display: inline-block; max-width: 57px;padding: 4px 10px;font-size: 12px;border-radius: 2px;color: #fff;border: 1px solid #000000;box-sizing: border-box;}

.NORMAL .box {background: #df663a;}
.PTN_1 .box {background: #d98f00;}
.PTN_2 .box {background: #af2a2e;}
.PTN_3 .box {background: #1d505c;}
.PTN_4 .box {background: #363b72;}
.PTN_5 .box {background: #2b476e;}

.names a .arrow {
  color: #7d1010;
  margin-right: 5px;
  font-weight: bold;
  letter-spacing: -2px;
  transform: rotate(180deg)
}
.names a:first-child .arrow{
  display: none;
}
.sticky {
  position: fixed;
  top: 175px;
  z-index: 1;
}
</style>
